<template>
  <div class="Terms">
    <p class="top_title">RealNetworks服务条款</p>
    <p class="content">最后修改日期为2020年7月7日。全部替换2020年6月2日版本。</p>
    <p class="content">重要提示：使用前请仔细阅读：本服务条款协议（“协议”）是您（“您”）与RealNetworks，Inc.（“RealNetworks”）之间就您使用RealNetworks网站和服务（“服务”）达成的法律协议，该等服务包括任何软件、移动应用程序和相关文档（“软件”）。</p>
    <p class="content">点击“我同意”按钮、选中“接受”复选框、安装或以其他方式使用服务，即表示您同意受本协议条款的约束。如果您不同意本协议的条款，请单击“取消”按钮，不要安装或以其他方式使用服务。您还确认您至少年满18岁或是独立生活的未成年人，或拥有父母或监护人的同意，并且完全有能力理解和同意本协议。在任何情况下，您确认您已超过您所在国家对于收集个人信息要求其父母或监护人同意的法定年龄。如果您未满此年龄，您可能无法使用这些服务。</p>
    <p class="content">RealNetworks是一家华盛顿州公司，您同意本协议受华盛顿州法律管辖，并根据下文第16节进行仲裁。此外，您同意，服务被视为仅以华盛顿州为基础，服务的网站应被视为被动网站，不会对华盛顿州以外的司法管辖区的RealNetworks产生属人管辖权。</p>
    <p class="content">REALNETWORKS对您提供给REALNETWORKS的个人和非个人信息的使用，以及对其收集的使用数据的使用受REALNETWORKS隐私权政策的规范，详见http://www.realnetworks.com/company/privacy/index.html.</p>
    <p class="content">注：本使用条款包含以下“仲裁”部分中的仲裁和集体诉讼豁免条款，该条款会对您在本使用条款下的权利，以及您与我们或我们的关联公司之间的任何争议处理产生影响。</p>
    <p class="title">1. 使用服务。</p>
    <p class="content">1.1授权。在遵守本协议和适用法律的前提下，您可以访问和使用服务。</p>
    <p class="content">1.2单独收费。您使用这些服务可能需要一台可以访问互联网的计算机或设备。您有责任支付与此类访问相关的所有费用。</p>
    <p class="content">1.3同时登录。您最多可以同时登录25个帐户。RealNetworks可能会通知您更改允许的登录次数。</p>
    <p class="content">1.4访问设备和第三方服务。您特此授权RealNetworks访问您选择的计算机和设备，以向您提供服务，并执行您选择的指令和设置。您还特此授权RealNetworks访问您选择的任何第三方帐户，以执行您选择的指令和设置。</p>
    <p class="content">1.5有偿服务。根据您选择的服务级别，您使用该服务可能需要支付费用。除本协议条款外，您对任何收费版本服务的使用应受到购买时提示的全部付款或订阅条款的约束。</p>
    <p class="content">1.6第三方服务的使用。这些服务可能允许您连接到第三方网站、应用程序和服务，以共享或上传您的内容。您对任何第三方网站、应用程序和服务的使用应受第三方隐私政策和条款的管辖。</p>
    <p class="title">2. 您的內容。</p>
    <p class="content">2.1分享您的内容。作为服务的用户，您可以在服务内上传内容，包括照片、视频、喜好和评论（“内容”）。某些服务可能包括允许您与其他用户共享内容和/或公开内容的功能。我们不监视或控制其他人对您的内容所做的操作。因此，一旦共享，其他人可能会使用、复制、修改、重新分发或公开共享您的内容。共享内容的后果由您全权负责，您有责任告知可如何使用您的内容的方式。</p>
    <p class="content">2.2所有权。您保留对内容的所有权利和所有权。我们不主张对您的内容拥有任何所有权。您还确认、陈述并保证，您拥有或拥有您上传到服务的内容以及您从服务中共享的内容所需的授权、权利、同意和权限。</p>
    <p class="content">2.3您的内容的授权。通过向服务中添加内容，您授予RealNetworks一个非独占、全球范围内、免版税、可再授权和可转让的许可权利，以使用、复制、公开显示、分发、修改和公开执行您的内容。本协议授予的许可仅限于提供、交付和改进服务或开发新服务。即使您停止使用本服务，本协议授予的许可证仍将继续有效。您可以随时通过从服务中删除内容来终止本许可证。但是，作为我们常规备份过程的一部分，您删除的内容的一些副本可能会在我们的服务器上保留一段合理的时间。</p>
    <p class="content">2.4许可您的反馈。您无需向RealNetworks提供有关服务的任何想法、评论、建议或其他反馈（“反馈”）。但是，如果您提供RealNetworks反馈，您同意这是自愿的。您还特此授予RealNetworks全额支付、永久、不可撤销、免版税、全球范围、可分许可和可转让的许可证，以将您的反馈用于任何目的，包括开发和改进RealNetworks的产品、服务和营销，以及复制、公开展示、分发，修改并执行反馈。</p>
    <p class="content">2.5访问您的内容。RealNetworks也可能出于运营服务的目的访问您的内容。这些行动可能包括（但不限于）响应您的支持请求、检测、预防或以其他方式解决欺诈、安全问题、非法行为，以及执行本协议。RealNetworks尊重您的隐私，您的内容受RealNetworks隐私政策的约束。</p>
    <p class="title">3. 软件许可。</p>
    <p class="content">如果我们向您提供软件作为服务的一部分，那么我们将授予您非独占许可，允许您根据本协议的条款和条件安装和使用软件，包括但不限于第4节规定的限制。</p>
    <p class="title">4. 您的行为。</p>
    <p class="content">您不得以不当方式使用服务。例如，您不得：</p>
    <ul>
      <li>在使用服务时共享内容或从事侵犯任何人知识产权的行为，包括著作权、道德权利、商标、商业外观、专利、商业秘密、隐私权、发表权或任何其他专有权利；</li>
      <li>分享非法的、有害的、具威胁性的、辱骂的、侵权的、污蔑的、诽谤的、低俗的、猥亵的、亵渎的、侵犯他人隐私的或引起仇恨的内容；</li>
      <li>违反任何适用法律，包括生成内容时用户所在辖区的管辖用户生成内容的管辖区法律；</li>
      <li>扰乱、干扰或禁止任何其他用户使用服务（例如通过跟踪、恐吓或骚扰他人、煽动他人实施暴力或伤害未成年人）；</li>
      <li>复制、修改、翻译、托管、再许可、租赁、转让、转售、逆向工程、反编译、拆解或创建服务的衍生作品；</li>
      <li>将服务用于商业、非个人目的，例如代表第三方存储、传输或分发内容，或运营商业应用程序或存储服务；</li>
      <li>通过RealNetworks提供或授权的接口以外的任何方式访问或尝试访问服务；/非永久性、MAC或IMEI）、硬件、软件、平台、浏览器类型，以及互联网协议（IP）地址。 </li>
      <li>规避为防止某些服务使用而设置的任何访问或使用限制； </li>
      <li>滥用服务和存储产品，或以其他方式试图禁用、损害或破坏服务；</li>
      <li>通过服务营销或宣传任何产品或服务，除非RealNetworks特别允许；</li>
      <li>冒充任何人或实体，或虚假陈述您与任何人或实体的关系，或允许他人使用您的账户信息使用服务；</li>
      <li>删除任何专有通知；也没有</li>
      <li>使用这些服务试图规避用于控制内容文件或受任何司法管辖区版权法保护的其他作品的访问或权利的技术措施。</li>
    </ul>
    <p class="content">任何此类被禁止的使用将立即终止您的许可证。此外，RealNetworks对儿童剥削采取零容忍政策。如果RealNetworks发现任何此类内容，我们将向相关部门报告。</p>
    <p class="title">5. 终止。</p>
    <p class="content">您可以随时通过取消订阅（如有）并卸载软件来终止使用我们的服务。RealNetworks可随时向您发出终止通知，终止服务。对于付费服务，任何到期退款的金额将由适用的订阅条款决定。此外，如果您未能遵守本协议，您访问这些服务的权利将自动终止，无需RealNetworks通知。由于任何原因终止访问后，您应停止所有服务的使用，并销毁软件的全部或部分副本。您承认RealNetworks可能会在不通知您的情况下删除与已取消、未使用的免费或过期帐户相关的内容。</p>
    <p class="title">6. 附加条款。</p>
    <p class="content">Chrome扩展功能。如果您使用Chrome扩展功能 StarSearch by Real（以下简称“扩展功能”），则适用以下附加条款：</p>
    <p class="content">该扩展功能为您提供了在网络视频中识别名人的选项，如演员、体育明星、音乐家、喜剧演员、政治家、YouTube网红等人士（以下简称“名人”）。一旦启用，该扩展功能将使用面部识别技术为您提供视频中名人的信息。通过从浏览器设置中禁用扩展，您可以随时禁用该功能。 </p>
    <p class="content">人物功能。如果您在RealPlayer中使用人物搜索或StarSearch功能（“人物功能”），则适用以下附加条款：</p>
    <p class="content">人物功能为您提供了按视频中的人物对RealPlayer媒体库中的视频进行索引的选项。一旦启用，您将能够按名人（如演员、体育明星、音乐家、喜剧演员、政治家和YouTube网红人士）为您的视频编制索引。人物功能有一个预填充的数据库，您可以将视频中出现的任何人物添加到数据库中，并创建自己的名人数据库。您添加的人物照片或视频将不会与RealNetworks共享，并将保留在您的设备上。您可以选择与RealNetworks共享与人物功能相关的特定信息，您共享的信息将在选择时进行详细描述。您可以随时使用RealPlayer应用程序中的设置禁用人物功能，也可以选择“删除所有人物数据”选项从设备中删除名人数据库和索引历史记录。</p>
    <p class="content">扩展功能和人物功能在某些司法管辖区（包括美国伊利诺伊州）不可用。 </p>
    <p class="content">RealTimes。如果您使用RealTimes移动应用程序（“RealTimes”），则适用以下附加条款：</p>
    <p class="content">（a） RealTimes服务使用自动技术方法，如地理位置标记、面部检测和光学字符识别，扫描您的内容，以编辑RealTimes故事并向您推荐这些故事。</p>
    <p class="content">（b） RealTimes服务为您提供了使用RealNetworks专有的面部识别技术对图像进行标记、搜索和排序的选项。一旦激活，该功能将使用面部识别技术来识别、匹配和标记媒体库中的其他图像。您可以随时使用RealTimes服务中的设置禁用该功能，也可以通过选择遗忘功能删除标签历史记录和任何相应的标识信息。如果您在三年内未访问RealTimes服务，您的标签历史记录和任何相应的身份信息也将被删除。</p>
    <p class="content">（c） 通过评论或喜欢其他用户的内容，您承认并同意您的评论和喜欢不会是私人的，并且可能对服务的其他用户可见。如果您不希望他人看到您的喜好和评论，请不要使用此功能。</p>
    <p class="content">（d） RealTimes服务提供预选和免版税的音频作品（“预选音频作品”），并允许您将其整合到RealTimes故事中。RealNetworks从版权所有者第三方处许可这些预选音频作品。您不得将预选音频作品用于商业用途。</p>
    <p class="content">（e） RealTimes服务还允许您在RealTimes故事中加入音频作品，而不是RealNetworks提供的预选音频作品。在这种情况下，您声明并保证此类音频作品的合并不会侵犯任何第三方权利，并且您同意完全赔偿RealNetworks因您违反本保证而造成的任何损失。</p>
    <p class="content">（f） 通过将标签“#myrealtimes”附加到您使用RealTimes服务创建的故事上，并在社交媒体网站上公开此类标记的视频，您就可以授予RealNetworks在其面向公众的网站上发布这些视频以及在社交媒体网站上重新共享或发布这些视频的许可。</p>
    <p class="title">7. 所有权。</p>
    <p class="content">服务的所有权、拥有权、权利和知识产权归RealNetworks所有。服务受美国版权法和国际版权条约的保护。</p>
    <p class="title">8. APP STORE。</p>
    <p class="content">通过应用商店购买软件也受该应用商店服务条款的约束。REALNETWORKS对与APP STORE交易相关的任何事项或争议不承担任何责任。</p>
    <p class="title">9. 无保证声明。</p>
    <p class="content">贵方明确承认并同意，使用本服务的风险由贵方自行承担，有关令人满意的质量、性能、准确性和努力的全部风险由贵方承担。在适用法律允许的最大范围内，服务按“原样”和“可用”提供，无任何故障，不提供任何形式的保证。REALNETWORKS特此否认与服务履行有关的所有明示、默示或法定保证和条件，包括但不限于适销性、令人满意的质量、特定用途的适用性、准确性和安静享受的默示保证和/或条件，以及不侵犯第三方权利。REALNETWORKS不保证您享受服务或软件时不受干扰，服务或软件中包含、执行或提供的功能将满足您的要求，服务软件的操作将不中断或无错误，或者服务或软件中的缺陷将得到纠正。REALNETWORKS或其授权代表提供的任何口头或书面信息或建议均不构成担保。一些司法管辖区不允许排除消费者适用法定权利的默示保证或限制，因此上述排除和限制可能不适用于您。</p>
    <p class="title">10. 责任限制。</p>
    <p class="content">在法律未禁止的范围内，REALNETWORKS在任何情况下均不对人身伤害或任何附带、特殊、间接或后果性损害负责，包括但不限于利润损失、数据损失、业务中断或任何其他商业损害或损失，因您使用或无法使用服务或软件而产生或与之相关，无论是何种原因造成的，无论责任理论（合同、侵权或其他）如何，即使REALNETWORKS已被告知此类损害的可能性。一些司法管辖区不允许对人身伤害、偶然或间接损害的责任进行限制，因此该限制可能不适用于您。在任何情况下，RealNetworks对您的所有损害赔偿（适用法律可能要求的人身伤害除外）的总责任不得超过50美元（$50.00）。即使上述补救措施未能达到其基本目的，上述限制也将适用。</p>
    <p class="title">11. 赔偿。</p>
    <p class="content">服务仅适用于获得适当许可的媒体、内容和内容创建工具。您对与服务一起使用的第三方内容的合法性全权负责，您有责任确定您的预期使用是否需要任何版权许可或其他协议。如果是这样，您也有责任获得服务和/或创建、压缩、转换或下载此类媒体和内容的任何此类许可证。您同意仅录制、转换、播放和下载您拥有必要版权许可证和其他许可和/或许可的媒体和内容。您进一步同意保护RealNetworks、其管理人员、董事、代理人和员工免受因您（i）查看、下载、上传、存储、编码、，违反另一方权利或任何法律，或（ii）违反本协议任何条款，修改、转换、压缩、复制或传输与服务有关的任何媒体和内容（RealNetworks提供的媒体和内容除外）。</p>
    <p class="title">12. 不得转让。</p>
    <p class="content">本协议是您的个人协议，未经RealNetworks明确书面同意，不得转让。</p>
    <p class="title">13. 技术支持。</p>
    <p class="content">RealNetworks的技术支持网站上介绍了技术支持：http://support.real.com/.</p>
    <p class="title">14. 出口及合规。 </p>
    <p class="content">除非获得美国法律和获取RealTimes信息所在司法管辖区的法律授权，否则您不得使用或以其他方式出口或再出口RealTimes信息。特别是，但不限于，RealTimes不得出口或再出口（a）到任何美国禁运国家或（b）美国财政部特别指定国民名单或美国商务部拒绝个人名单或实体名单上的任何人。您声明并保证您不在任何此类国家或任何此类名单上。您还同意，您不会将RealTimes用于美国法律禁止的任何目的。</p>
    <p class="title">15. 美国政府最终用户。</p>
    <p class="content">实时和相关文件是48 C.F.R.§2.101中定义的“商业物品”，由“商业计算机软件”和“商业计算机软件文件”组成，如48 C.F.R.§12.212或48 C.F.R.§227.7202（如适用）中所用。符合48 C.F.R.§12.212或48 C.F.R.§227.7202-1至227.7202-4（如适用），商业计算机软件和商业计算机软件文档仅许可给美国政府最终用户（a）作为商业项目和（b）根据本协议条款和条件授予所有其他最终用户的权利。</p>
    <p class="title">16. 仲裁与争议解决。</p>
    <p class="content">请点击这里以查看仲裁与争议解决条款，该条款已并入本服务条款。请仔细阅读该条款。该条款会影响您与REALNETWORKS之间任何赔偿诉讼或争议解决的方式。</p>
    <p class="title">17. 著作权侵权通知。</p>
    <p class="content">17.1 DMCA。RealNetworks尊重他人的知识产权，并希望其用户也这样做。根据1998年《数字千年版权法》，其文本可在美国版权局网站上找到，网址为http://www.copyright.gov/legislation/dmca.pdf，RealNetworks将立即回复著作权侵权通知，前提是此类通知是根据本节提交的。</p>
    <p class="content">17.2下架通知。如果您是著作权所有人或被授权代表版权所有人行事，并且您认为您的作品因服务而受到侵犯，请提供一份指控侵权的书面通知（“通知”），其中包含以下内容：，通过常规邮件或传真（而非电子邮件或电话）发送至RealNetworks指定的著作权代理人Cyrus Christenson。</p>
    <p class="content">邮件寄送：</p>
    <p class="content">Cyrus Christenson</p>
    <p class="content">RealNetworks, Inc.
1501 1st Avenue South</p>
    <p class="content">Suite 600</p>
    <p class="content">Seattle, Washington 98134</p>
    <p class="content">传真：</p>
    <p class="content">(206) 674-2695</p>
    <p class="content">Attn: Cyrus Christenson</p>
    <p class="content">17.3 通知內容</p>
    <p class="content">（a） 确定您声称其著作权受到侵犯的作品，或者如果通知涵盖多个著作权作品，您可以提供您声称受到侵犯的著作权作品的代表性列表；</p>
    <p class="content">（b） 网站上显示的链接的URL或可能找到此类材料的确切位置；</p>
    <p class="content">（c） 您的法定全名、公司隶属关系（如适用）、邮寄地址、电话号码，以及电子邮件地址（如有）；</p>
    <p class="content">（d） 包括一份声明，表明您真诚地相信，版权所有人、其代理人或法律未授权争议使用受版权保护的材料；</p>
    <p class="content">（e） 包括一份声明，说明本通知中的信息准确无误，并且根据伪证处罚，您是据称被侵犯的专有权的所有者，或被授权代表所有者行事；和</p>
    <p class="content">（f） 您的物理或电子签名。</p>
    <p class="content">17.4反通知。如果您认为RealNetworks因不当侵权索赔而禁用或删除了对您作品的访问，请根据DMCA，通过常规邮件或传真（而非电子邮件或电话）向我们的版权代理提供书面通知，联系方式如下：</p>
    <p class="content">（a） 确定从服务中移除的材料，以及移除前在服务中出现的位置；</p>
    <p class="content">（b） 在伪证处罚下，包括一份声明，表明您真诚地相信，由于错误或误认要删除或禁用的内容，该内容已被删除或禁用；</p>
    <p class="content">（c） 提供您的姓名、公司隶属关系（如适用）、邮寄地址、电话号码，以及电子邮件地址（如有）；</p>
    <p class="content">（d） 一份声明，表明您同意联邦地区法院对您居住的地区（或华盛顿州西雅图，如果您居住在美国境外）的管辖权，并表示您将接受根据DMCA提供通知的人的法律程序送达；和</p>
    <p class="content">（e） 您的物理或电子签名。</p>
    <p class="title">18. 其他。</p>
    <p class="content">本协议构成您与RealNetworks之间的完整独家协议。RealNetworks可随时修改本协议。除非您和RealNetworks授权代表以书面形式正式签署，否则您不得修改本协议。如果本协议的任何条款因任何原因被认定为不可执行，则该条款仅在使其可执行所需的范围内可分割，且该决定不得影响该条款在其他情况下的可执行性，或在所有情况下本协议其余条款的可执行性。本协议不受《联合国国际货物销售合同公约》管辖，该公约的适用在此明确排除</p>
    <p class="content">版权所有 © 2020 RealNetworks, Inc. 1501 1st Ave.South, Suite 600, Seattle, WA 98134 U.S.A.</p>
  </div>
</template>
<script>
  export default {
   name: 'Terms',
   created:function(){
      window.scrollTo(0,0);

   },
   mounted:function(){
        var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = "./favicon_term.ico";
        document.getElementsByTagName("head")[0].appendChild(link);
   },
   destroyed:function(){
     var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = "./favicon.ico";
        document.getElementsByTagName("head")[0].appendChild(link);
   },
  methods:{
     
  }
}
</script>
<style scoped>
  .Terms{width:60%;margin: 0 auto;}
  .top_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .title{font-size: 20px;font-weight: bold;margin: 10px 0;}
  .content{text-indent:2em;font-size: 14px;line-height: 28px;}
  ul{margin-left: 40px;}
  li{font-size: 14px;line-height: 28px;}
</style>